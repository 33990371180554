import AboutUs from '@components/AboutUs'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    contentfulAboutUs {
      headerText
      headerImage {
        file {
          url
        }
      }
      headerSubtitle
      headerParagraph {
        headerParagraph
      }
      companiesSubtitle
      companiesParagraph {
        json
      }
      companiesImage {
        file {
          url
        }
      }
      aboutUsSubtitle
      aboutUsParagraph {
        aboutUsParagraph
      }
      aboutUsImage {
        file {
          url
        }
      }
      aboutUsInfo {
        header
        title
        description {
          description
        }
      }
      coreValuesSubtitle
      coreValuesParagraph {
        coreValuesParagraph
      }
      coreValuesImage {
        file {
          url
        }
      }
      leadersSubtitle
      leadersParagraph {
        leadersParagraph
      }
      leadersImage {
        file {
          url
        }
      }
      showCompanies
      hideCompanyImages
      companies {
        companyName
        insuranceCategory
        about {
          json
        }
        companyWebsite {
          url
        }
        logo {
          description
          file {
            url
          }
        }
        marketingImage {
          description
          file {
            url
          }
        }
      }
      coreValues {
        header
        title
        description {
          description
        }
      }
      leaders {
        anchor {
          url
        }
        header
        subHeader {
          subHeader
        }
        members {
          image {
            description
            file {
              url
            }
          }
          name
          title
          company
          education {
            education
          }
          fullTitle
          experience {
            experience
          }
          displaySection
        }
        logoA {
          description
          file {
            url
          }
        }
        logoB {
          description
          file {
            url
          }
        }
        logoC {
          description
          file {
            url
          }
        }
        logoD {
          description
          file {
            url
          }
        }
        logoE {
          description
          file {
            url
          }
        }
        logoF {
          description
          file {
            url
          }
        }
        logoG {
          description
          file {
            url
          }
        }
      }
      showFinancialStrength
      financialStrengthSubtitle
      financialStrengthParagraph {
        json
      }
      financialStrengthImage {
        file {
          url
        }
      }
    }
  }
`

export default AboutUs
